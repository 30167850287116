@import './variables';
@import './mixins';

.secure-dashboard-page {
    .content {
        padding-top: 16px;
    }
}
.secure-contract-list-page {
    .contract-filter {
        @include row-spacebetween-center;
        @include media($width-800) {
            display: block;

            h1 {
                line-height: 1 !important;
            }
        }
        .contract-filter-inner {
            @include row-right-center;
            @include media($width-small) {
                display: block;
            }
        }
    }
    .button-new {
        margin-right: 16px;
    }
    .contracts {
        > .item {
            margin-top: 16px;
            border-bottom: 1px solid $item-border;
            .item-title {
                cursor: pointer;
                background: url('../assets/icon-arrow-right-orange.png') no-repeat center right;
                .title-product {
                    @include font-size-21;
                    font-weight: 500;
                    color: $blue-second;
                }
            }
            .detail {
                @include row-left-top;
                margin-top: 16px;

                @include media($width-small) {
                    display: block;
                }

                .detail-item {
                    width: 146px;
                    @include font-size-17;
                    font-weight: 500;
                    color: $color-grey-dark;
                    display: inline-block;

                    .add-documents {
                        background: url('../assets/icon-new-plus-small-grey.png') no-repeat center left;
                        height: 32px;
                        @include font-size-12;
                        color: $grey;
                        box-sizing: border-box;
                        display: inline-block;
                        cursor: pointer;
                        position: relative;
                        right: 8px;
                        padding-left: 32px;
                        padding-top: 8px;
                    }
                }
            }
            .title {
                @include font-size-14;
                font-weight: 500;
                line-height: 20px;
                color: $color-input-title;
            }
            .cashback-status {
                @include row-left-center;
                @include font-size-12;
                font-weight: 600;
                height: 44px;
                line-height: 44px;

                span {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    border-radius: 8px;
                    background-color: $color-open;
                    margin-right: 5px;
                }
                &.reject,
                &.active_without_fee {
                    span {
                        background-color: $color-reject;
                    }
                    color: $color-reject;
                    cursor: pointer;
                    text-decoration: underline;
                }
                &.open,
                &.deactivation_broker {
                    span {
                        background-color: $color-onwork;
                    }
                    cursor: pointer;
                    text-decoration: underline;
                }
                &.active_with_payment {
                    span {
                        background-color: $color-activate;
                    }
                }
            }
        }
    }

    .contract-popup-text {
        @include font-size-16;
        padding-bottom: 24px;
    }
}

.secure-contract-detail-page,
.secure-contract-documents-page,
.secure-customer-profile-page {
    .info {
        align-items: stretch;
        align-content: stretch;
        margin-bottom: 20px;

        @include media($width-vsmall) {
            display: block;
        }

        .inner {
            .detail-container {
                margin-top: 16px;
                @include row-spacebetween-center;

                @include media($width-vsmall) {
                    @include column-left-top;
                }
            }
            .detail {
                @include row-left-center;
                @include font-size-17;
                font-weight: 500;
                color: $color-grey-dark;

                @include media($width-big) {
                    display: block;
                }

                .detail-item {
                    display: inline-block;
                    width: 140px;
                    margin-right: 5px;
                    margin-top: 10px;

                    .cashback {
                        text-decoration: underline;
                    }
                }
            }
        }
        .inner-buttons {
            > div {
                margin-bottom: 8px;
                padding-top: 12px;
                padding-bottom: 12px;
            }
        }
    }

    .contract {
        width: 460px;

        @include media($width-vsmall) {
            width: auto;
        }

        .item {
            display: inline-block;
            width: 200px;
            margin-right: 30px;
            @include font-size-17;
            font-weight: 500;
            min-height: 40px;
        }
    }

    .title {
        @include font-size-14;
        font-weight: 500;
        line-height: 20px;
        color: $color-input-title;
    }
}

.secure-contract-detail-page,
.secure-contract-documents-page {
    .header-secure {
        .children {
            h1 {
                padding: 0px;
                line-height: 1.5;
            }
        }
    }
}

.secure-contract-documents-page {
    .list-header {
        @include row-spacebetween-center;
        .year {
            width: 90px;
        }
    }
    .list {
        .item {
            @include row-spacebetween-center;
            width: 100%;
            @include font-size-14;
            border-bottom: 1px solid $item-border;

            > div {
                @include row-left-center;

                > .date {
                    padding-right: 32px;
                }
            }

            .title {
                @include font-size-17;
                font-weight: 500;
                color: $orange;
                text-decoration: underline;
                cursor: pointer;
            }
            .delete {
                @include touchArea;
                background: url('../assets/icon-trash.png') center center;
            }
        }
    }
    .no-padding {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

.secure-customer-profile-page {
    .detail {
        @include row-left-top;
        flex-wrap: wrap;

        h4 {
            @include font-size-16;
            font-weight: 300;
        }

        .item {
            width: calc($width-big / 4);
            min-height: 60px;
            margin-top: 16px;

            > div {
                @include font-size-17;
                font-weight: 500;
                color: $color-grey-dark;

                &.title {
                    @include font-size-14;
                    font-weight: 500;
                    color: $color-input-title;
                    padding-bottom: 5px;
                }
            }

            @include media(calc($width-big / 4)) {
                width: 100%;
            }

            &.item-email {
                width: calc($width-big / 2);

                @include media(calc($width-big / 2)) {
                    width: 100%;
                }
            }

            .email-not-confirmed {
                margin-top: 6px;
                color: $red;
                @include font-size-12;
                span {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
        .item-buttons {
            width: 100%;

            > div {
                padding-top: 10px;
            }
        }
        h4 {
            @include font-size-14;
            font-weight: 500;
            color: $blue-second;
            width: 100%;
            margin-bottom: 0px;
        }
    }
}

.secure-customer-referral-page {
    .wrapper-big {
        h2 {
            @include font-size-18;
            font-weight: 600;
            line-height: 26px;
            padding: 17px 0px 17px 0px;
        }

        .share-qr-note {
            @include font-size-10;
            line-height: 14px;
            margin-top: 40px;
        }

        .container-referral-description {
            @include font-size-16;
            line-height: 22px;
            margin: 19px 0px 22px 0px;
        }
    }

    .container-referral {
        @include font-size-16;
        margin-top: 14px;
        line-height: 22px;

        h3 {
            @include font-size-18;
            font-weight: 600;
            line-height: 26px;
            padding: 17px 0px 17px 0px;
        }

        .container-qr {
            margin-top: 24px;
            display: flex;
            justify-content: space-between;

            @include media($width-vsmall) {
                flex-wrap: wrap;
            }

            .share-qr-code-image,
            .share-qr-copy-input {
                height: 202px;
                border-radius: 8px;
                border: 1px solid $color-dark-blue;
                padding: 30px 0px;
                display: flex;
                justify-content: center;
            }

            .share-qr-copy-input {
                padding: 0px 30px;
                justify-content: left;
                width: 100%;
                align-items: center;

                .share-qr-copy-text {
                    width: 100%;
                    font-weight: 600;
                }
            }

            .share-qr-code,
            .share-qr-copy {
                width: 482px;
            }

            .share-qr-code-btndownload,
            .share-qr-copy-button {
                width: 100%;
                margin-top: 10px;

                .button,
                button {
                    width: 100%;

                    &:hover {
                        background-color: $color-light-green;
                        -webkit-transition: all 0.3s;
                    }
                }
            }

            .share-qr-copy-button {
                button {
                    text-transform: uppercase;
                    color: $white;
                    text-align: center;
                    padding: 18px 30px;
                    box-sizing: border-box;
                    display: inline-block;
                    cursor: pointer;
                    background: $color-dark-blue;
                    border: 1px solid $color-dark-blue;
                    border-radius: 8px;
                }
            }
        }
    }
}
