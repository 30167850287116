.button-link {
    @include row-left-center;
    @include font-size-17;
    font-weight: 300;
    text-decoration: underline;
    cursor: pointer;

    &.small {
        @include font-size-12;
    }

    &.blue {
        @include font-size-12;
        color: $color-button-link-blue;
    }

    &.red {
        color: $color-error;
    }
}

.button {
    @include column-center-center;
    padding: 12px 30px;
    box-sizing: border-box;
    display: inline-block;
    cursor: pointer;
    background: $color-button;
    border: 1px solid $color-button;
    border-radius: 24px;

    &:hover,
    &:focus {
        background: $color-button-hover;
    }

    &:hover {
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
    }

    .inner {
        @include row-center-center;
        .text {
            @include font-size-17;
            text-align: center;
            font-weight: 700;
            color: $color-button-text;

            .info {
                @include font-size-12;
                text-transform: none;
                color: $color-dark-blue;
                padding-top: 5px;
            }
        }
        .left-icon {
            display: inline-block;
            margin-right: 16px;
        }
    }

    &.right-icon,
    &.right-icon-black {
        padding-right: 26px;
        background-image: url('../assets/icon-arrow-btn-white.png');
        background-position: center right;
        background-repeat: no-repeat;
        min-width: 200px;
        @include media($width-vsmall) {
            padding: 20px 20px;
        }
    }
    &.right-icon-black {
        background-image: url('../assets/icon-arrow-right-black.png');
    }

    &.white {
        background-color: $color-button-white;
        border: 2px solid $black;

        .inner {
            .text {
                color: $color-button-text-white;
            }
        }

        &:hover {
            background-color: $color-hover-btn-white;
        }
    }

    &.transparent {
        background-color: transparent;
        border: 2px solid $black;

        &.right-icon {
            background-image: url('../assets/icon-arrow-right-black.png');
        }

        .inner {
            .text {
                color: $black;
            }
        }

        &:hover {
            background-color: $color-hover-btn-white;
        }
    }

    &.transparent-orange {
        background-color: transparent;
        border: 1px solid $orange;

        &.right-icon {
            background-image: url('../assets/icon-arrow-right-black.png');
        }

        .inner {
            .text {
                color: $orange;
            }
        }

        &:hover {
            //background-color: $orange;
        }
    }

    &.left-align {
        @include row-left-center;
        padding-left: 16px;
        .inner {
            @include row-left-center;
            .text {
                text-align: left;
            }
        }
    }

    &.full-width {
        padding: 20px 20px;
        width: 100%;
        border-radius: 40px;
    }

    &.margin-none {
        margin: 0px;
    }

    &.padding-none {
        padding: 0px;
    }

    &.no-bold {
        .inner {
            .text {
                font-weight: normal;
            }
        }
    }

    &.no-circle-border {
        border-radius: 8px;
    }

    &.width-200 {
        width: 200px;
    }

    @include media($width-vsmall) {
        .inner {
            .text {
                width: 250px;
            }
        }
    }

    @include media($width-se) {
        .inner {
            .text {
                width: 220px;
            }
        }
    }
}

.button-dashboard {
    @include column-left-spacebetween;
    @include font-size-14;
    font-weight: 500;
    padding: 10px 4px 10px 0px;
    border: 2px solid $color-button-dashboard;
    border-radius: 6px;
    cursor: pointer;
    min-height: 135px;
    min-width: 114px;
    margin-right: 10px;
    box-sizing: border-box;

    .text {
        padding-left: 4px;

        .title {
            width: 90px;
        }

        img {
            width: 8px;
        }
    }

    @include media($width-iphone12) {
        min-width: 100px;
    }
}
.button-new {
    @include column-center-center;
    @include font-size-14;
    font-weight: 500;
    box-sizing: border-box;
    border: 1px solid $orange;
    color: $orange;
    border-radius: 23px;
    height: 46px;
    display: inline-block;
    padding: 13px 16px 4px 44px;
    cursor: pointer;
    background: url('../assets/icon-new-plus-orange.png') no-repeat center left;
}
