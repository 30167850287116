// GLOBALS
// .only-* and .is-*

.only-sr {
    @include hidden;
}

.is-disabled {
    opacity: 0.5;
    pointer-events: none;
}

.is-unselectable {
    user-select: none;
}

.is-hidden {
    @include hidden;
}

.is-loading {
    position: relative;

    &:before {
        content: '';
        display: block;
        background-color: $white;
        bottom: 0;
        left: 0;
        opacity: 0.6;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 8;
    }

    &:after {
        content: '';
        animation: spin 1.5s infinite linear;
        //border-left: 4px solid rgba($color-800, 0.2);
        //border-bottom: 4px solid rgba($color-800, 0.2);
        //border-right: 4px solid rgba($color-800, 0.2);
        //border-top: 4px solid $color-one-16;
        border-radius: 100%;
        display: block;
        height: 32px;
        left: 50%;
        margin: -16px 0 0 -16px;
        outline: 1px solid transparent;
        position: absolute;
        top: 50%;
        width: 32px;
        z-index: 2;
    }

    &.is-loading-small {
        &:after {
            border-width: 3px;
            height: 16px;
            margin: -8px 0 0 -8px;
            width: 16px;
        }
    }
}

.margin-top-16 {
    margin-top: 16px;
}

.margin-top-32 {
    margin-top: 32px;
}

.margin-top-48 {
    margin-top: 48px;
}

.padding-left-16 {
    padding-left: 16px;
}

.margin-left-16 {
    margin-left: 16px;
}

.touch-height {
    height: 44px;
}
.row-left-center {
    @include row-left-center;
}
.row-right-center {
    @include row-right-center;
}
.row-spacebetween-center {
    @include row-spacebetween-center;
}
.row-spacebetween-end {
    @include row-spacebetween-end;
}
.pointer {
    cursor: pointer;
}
.text-orange {
    color: $orange;
}
