//elements
.container-text-file-element {
    .title {
        @include font-size-21;
        font-weight: 500;
        color: $blue-second;
        padding: 16px 0px;
    }

    .category {
        @include font-size-16;
        font-weight: bold;
        padding-bottom: 16px;
    }

    .subcategory,
    .subsubcategory {
        @include font-size-14;
        font-weight: bold;
        padding-bottom: 16px;
    }

    .text {
        @include font-size-14;
        padding-bottom: 16px;
    }
}

.search-input-element {
    width: 100%;
    border: 2px solid $white;
    height: 55px;
    background-image: url('../assets/icon-search-white.png');
    background-position: center right;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding: 0px 50px 0px 16px;
    border-radius: 8px;

    input {
        border: 0px;
        height: 55px;
        width: 100%;
        line-height: 55px;
        @include font-size-16;
        color: $white;
        background: none;

        @include placeholder {
            @include font-size-16;
            color: $white;
        }
    }
}

.provider-element {
    .title {
        @include font-size-14;
        font-weight: 500;
        color: $color-input-title;
    }
    .text {
        @include font-size-17;
        font-weight: 500;
        margin-top: 4px;
        color: $black;
    }
}

.youtube-image {
    position: fixed;
    bottom: 120px;
    right: 16px;
    display: inline-block;
    z-index: 200;
    @include media($width-normal) {
        bottom: 20px;
        //right: 250px;
    }
    //@include media($width-vsmall) {
    //    display: none;
    //}

    .youtube-inner {
        width: 180px;
        height: 180px;
        border: 1px solid $color-youtube-border;
        cursor: pointer;

        .youtube-img {
            width: 180px;
            height: 120px;
        }

        .youtube-text {
            @include font-size-12;
            color: $color-dark-blue;
            padding: 10px 16px 0px;
        }
    }
}

.provider-image {
    @include column-center-center;
    box-sizing: border-box;
    //height: 50px;
    width: 120px;
    border-radius: 6px;
    background-color: $color-contract-provider-image-bg;
    border: 1px solid $color-contract-provider-image-border;
    min-height: 60px;

    img {
        //height: 50px;
        width: 120px;
        border-radius: 6px;
    }

    .title {
        @include font-size-12;
        color: $color-contract-provider-image-text;
        text-align: center;
    }

    &.provider-header {
        background-color: $white;
        border-color: $black;
    }
}

.loading {
    @include column-center-center;
    position: fixed;
    background: $color-popup-bg-out;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    top: 0px;
    left: 0px;
    z-index: 100;
}

.search-widget {
    display: inline-block;

    .inner {
        @include row-left-center;

        .search {
            display: inline-block;
            border: 1px solid $color-button-dashboard;
            border-radius: 8px;
            height: 45px;
            background: url('../assets/icon-search.png') no-repeat center right;
            padding-right: 45px;
            margin-right: 9px;
            input {
                border: none;
                width: 200px;
                @include font-size-14;
                font-weight: 500;
                height: 42px;
                background: none;
                padding: 0px 10px;
                color: $color-grey-dark;

                @include media($width-small) {
                    width: 161px;
                }

                @include placeholder {
                    color: $color-grey-dark;
                    //text-transform: uppercase;
                }

                &:focus {
                    outline: none;
                }
            }
        }

        .filter__control,
        .order__control {
            border: none !important;
            background: none;
            width: 48px;
            height: 48px;

            .filter__indicators,
            .order__indicators {
                border: none !important;
                background: none;
                width: 48px;
                height: 48px;
                display: inline-block;
                padding: 0px;

                .filter__indicator,
                .order__indicator {
                    padding: 0px;

                    .btn-drop {
                        width: 48px;
                        height: 48px;
                        background: url('../assets/icon-btn-filter.png') no-repeat;
                        cursor: pointer;
                    }
                }
            }
        }
        .filter__menu,
        .order__menu {
            width: 150px !important;
            //position: absolute;
            right: 10px;
        }
        .order__control {
            margin-right: 9px;
            .order__indicators {
                .order__indicator {
                    .btn-drop {
                        background: url('../assets/icon-btn-order.png') no-repeat;
                    }
                }
            }
        }
    }
}

.expert-content-element {
    @include gradient-main;

    .wrapper {
        @include column-center-center;
        padding: 40px 24px;

        .texts {
            @include font-size-17;
            font-weight: 700;
            text-align: center;
            color: $white;
            margin-top: 32px;

            .text {
                @include font-size-17;
                font-weight: 500;
                margin-top: 28px;
            }
        }
    }
}
