// GLOBALS
.icon {
    display: inline-block;
    fill: currentColor;
    height: 1em;
    width: 1em;
    vertical-align: middle;

    &.icon-inline {
        position: relative;
        top: -0.125em;
    }

    .svg {
        display: block;
        height: 100%;
        width: 100%;
    }
}
.link {
    text-decoration: underline;
    cursor: pointer;
}
