.header {
    @include gradient-main;
    width: 100%;
    padding-bottom: 5px;
    position: sticky;
    top: 0;
    z-index: 100;

    .top,
    .top-with-back {
        @include column-right-center;
        height: 70px;
        padding-right: 20px;
        width: 100%;

        .logo {
            width: 181px;
            height: 39px;
        }
    }

    .top-with-back {
        @include row-spacebetween-center;

        .back {
            @include touchArea;
            background: url('../assets/icon-back-white.png') center left no-repeat;
            cursor: pointer;
        }
    }

    .expert {
        margin: 20px 0px 20px;
        width: 100%;

        .wrapper {
            @include row-left-center;

            img: {
                width: 55px;
            }

            .texts {
                padding-left: 22px;
                .titleExpert {
                    @include font-size-20;
                    font-weight: 300;
                    color: $white;
                    padding-bottom: 16px;
                }
                .title {
                    @include font-size-17;
                    font-weight: 700;
                    color: $white;
                }
                .text {
                    padding-top: 5px;
                    @include font-size-17;
                    font-weight: 300;
                    line-height: 22px;
                    color: $white;
                }
            }
        }

        &.expert-info {
            .wrapper {
                @include row-left-end;
                @include wrapper-xy($width-vsmall);
                margin-top: 80px;
            }
        }
    }

    h1 {
        @include font-size-32;
        font-weight: 500;
        color: $white;
        padding-bottom: 10px;
    }

    .children {
        padding-bottom: 20px;
    }

    //.wrapper {
    //    padding-bottom: 28px;
    //}
}

.header-secure {
    background: $color-header-secure-bg;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 100;

    .top {
        .inner {
            @include row-left-center;
            min-height: 50px;

            @include media($width-iphone12) {
                min-height: 46px;
            }

            .left {
                width: 81px;

                .back {
                    @include touchArea;
                    background: url('../assets/icon-arrow-left-black-small.png') no-repeat center left;
                    cursor: pointer;
                }
                .logo {
                    width: 135px;
                    height: 30px;
                    cursor: pointer;
                    @include media($width-iphone12) {
                        display: none;
                    }
                }
            }
            .right {
                @include row-right-center;
                flex: 1;

                .menu-top {
                    @include row-right-center;
                    div {
                        padding: 16px 10px;
                        cursor: pointer;
                        color: $white;
                        @include font-size-17;
                        font-weight: 700;

                        &.selected,
                        &:hover {
                            background: $blue-first;
                            color: $blue-second;
                        }
                        &:hover {
                            transition: all 0.5s;
                            -webkit-transition: all 0.5s;
                        }

                        //@include media($width-iphone12) {
                        //    @include font-size-12;
                        //}
                    }
                }
                .menu-slide-icon {
                    @include column-center-center;
                    height: 44px;
                    width: 44px;
                    cursor: pointer;
                    img {
                        height: 44px;
                        width: 44px;
                    }
                }
            }
        }
    }
    .children {
        padding-bottom: 16px;
        h1 {
            @include font-size-32;
            font-weight: 500;
            color: $blue-second;
        }
    }
    &.blue-gradient {
        .top {
            @include gradient-main;
        }
    }
}

.bottom {
    background: $color-bottom-bg;
    width: 100%;
    padding: 14px 0;
    position: fixed;
    bottom: 30px;
    left: 0;

    .wrapper-big {
        @include row-right-center;
        padding-bottom: 0px;
    }
}

.bottom-links {
    @include column-center-center;
    height: 30px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: $color-bottom-links-bg;
    z-index: 200;

    .wrapper {
        @include row-spacearound-center;
        width: 230px;
    }

    &.bottom-links-blue {
        background: $color-header-bg;

        .button-link {
            color: $white;
        }
    }
}

.menu-slide {
    width: 150px;
    height: 100vh;
    box-sizing: border-box;
    background: $color-gradient-end;
    position: fixed;
    right: -150px;
    top: 0px;
    z-index: 10000;
    padding: 4px 10px 16px 0px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;

    &.opened {
        transform: translate3d(-150px, 0, 0);
        animation-timing-function: ease-in;
    }
    .menu-slide-inner {
        @include column-right-top;

        .icon-menu {
            cursor: pointer;
            img {
                width: 44px;
                height: 44px;
            }
        }

        .item {
            color: $white;
            @include font-size-12;
            text-transform: uppercase;
            padding-right: 5px;
            line-height: 44px;
            height: 44px;
            cursor: pointer;
        }
    }
}
