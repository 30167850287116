// variables
@import './fonts';
@import './variables';
@import './mixins';

// globals
@import './keyframes';
@import './normalize';
@import './reset';
@import './global';
@import './modifier';
@import './form';
@import './popups';
@import './elements';
@import './buttons';
@import './themes';

.app {
    background-color: $white;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    height: 100vh;
    overflow-y: hidden;

    .content {
        padding-bottom: 150px;
        flex: 1;
        overflow-y: scroll;
    }

    span {
        &.bold {
            font-weight: bold;
        }

        &.underline {
            text-decoration: underline;
        }

        &.newline {
            display: block;
            min-height: 22px;
        }
    }

    .loading-icon {
        @include column-center-center;
        margin-top: 64px;
    }

    &.onboarding-finapi-error-page,
    &.onboarding-finapi-restart-page {
        background-color: $color-header-bg;
    }
    &.general-message-page {
        background-color: $white;

        .content {
            @include column-center-center;
            @include scroll-whole-page-without-color;
        }
    }

    @include media($width-iphone12) {
        @include scroll-whole-page;
    }

    .back-in-inner {
        @include touchArea;
        background: url('../assets/icon-arrow-back-orange.png') center left no-repeat;
        cursor: pointer;
    }
}

.wrapper {
    @include wrapper-xy();
}

.wrapper-small {
    @include wrapper-xy($width-small);
}

.wrapper-vsmall {
    @include wrapper-xy($width-vsmall);
}

.wrapper-big {
    @include wrapper-xy($width-big);
}

.hidden {
    @include hidden;
}

.display-inline {
    display: inline-block;
}
