@import './variables';
@import './mixins';

.terms-page,
.signup-step-3-page {
    .item {
        @include row-left-center;
        width: 100%;
        margin-bottom: 50px;

        .item-number {
            @include column-center-center;
            width: 56px;
            height: 56px;
            border-radius: 28px;
            background: $blue-first;
            color: $blue-second;
            font-weight: bold;
            @include font-size-20;
            margin-right: 24px;
        }

        .item-content {
            flex: 1;

            .item-title {
                @include font-size-20;
                font-weight: 500;
                color: $blue-second;
            }

            .item-text {
                @include font-size-17;
                font-weight: 300;
                padding-top: 8px;
                color: $color-black-08;
            }

            .item-link {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
    .conditions {
        position: fixed;
        bottom: 110px;
        right: 40px;
        .conditions-container {
            box-sizing: border-box;
            border-radius: 4px;
            width: 250px;
            height: 180px;
            background: $orange;
            margin-bottom: 12px;

            .boxitem {
                padding-top: 10px;
                padding-left: 10px;
                .check-text {
                    width: 170px;
                }
                .check-text,
                .button-link {
                    color: $white;
                    @include font-size-16;
                    font-weight: 500;
                    line-height: 20px;
                }
            }
        }
        .button {
            width: 250px;
        }
    }
}
