@import './variables';
@import './mixins';

.signup-step-1-page {
    .bottom-list-text {
        ul {
            margin-top: 57px;
            padding: 0px;

            li {
                @include font-size-14;
                line-height: 36px;
                color: $color-a;
                list-style: none;
                padding-left: 30px;
                background: url('../assets/icon-check-black.png') -14px center no-repeat;
            }
        }
    }
}

.signup-step-4-page,
.signin-page,
.auth-start-page,
.auth-forgot-send-page,
.auth-forgot-confirm-page {
    @include gradient-main;

    .header {
        padding-top: 50px;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: unset;
        background: none;
        box-shadow: none;

        .top {
            @include row-center-center;

            img {
                &.logo {
                    width: 270px;
                    height: 60px;
                }
            }
        }

        h1 {
            @include font-size-20;
            font-weight: normal;
            width: 100%;
            line-height: 60px;
            color: $white;
            text-align: center;
            margin-top: 16px;
        }

        .desc {
            @include font-size-16;
            line-height: 32px;
            margin-bottom: 16px;
        }
    }

    .bottom-links {
        &.bottom-links-blue {
            background: none;
        }
    }

    .content {
        .wrapper {
            background-color: $color-bg;
            border-radius: 8px;
            padding: 20px 0px 35px;

            h1 {
                @include font-size-32;
                color: $blue-second;
                font-weight: 500;
            }

            .desc {
                color: $color-black-08;
            }

            .button-link {
                height: 19px;
                display: block;
                margin-top: 25px;

                &.margin-top-70 {
                    margin-top: 70px;
                }
            }

            .row-center {
                @include row-center-center;
            }
        }
    }

    .readonly-field {
        .text {
            @include font-size-14;
            border: 0px;
            height: 28px;
            width: 100%;
            line-height: 28px;
            font-weight: bold;
            color: $color-a;
            padding: 0px;
        }

        .inner {
            background-image: unset;
        }
    }

    .margin-top-12 {
        margin-top: 12px;
    }
}

.auth-start-page {
    .content {
        .wrapper {
            .checkbox {
                position: relative;
                left: -15px;
                .title {
                    @include font-size-14;
                    font-weight: 350;
                    color: $black;
                }
            }
        }
    }
}

.wrapper-top-inner,
.wrapper-steps {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .form-steps-separator-line {
        width: 33px;
        height: 3px;
        background-color: $blue-second;
    }

    .form-steps-circle {
        @include font-size-20;
        background-color: $color-bg;
        color: $blue-second;
        width: 29px;
        height: 29px;
        line-height: 27px;
        text-align: center;
        border-radius: 50%;
        font-weight: bold;
        font-size: 16px;
        border: 2px solid $blue-second;

        &.active {
            color: $white;
            background-color: $blue-second;
        }

        img {
            position: relative;
            top: -2px;
        }
    }
}

.sms-verification {
    margin-top: 25px;

    .wrapper-inputs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
    }

    .prefix-text-sms-verif,
    .timer-text-sms-verif {
        font-weight: 500;
        @include font-size-17;
    }
    .timer-text-sms-verif {
        color: $color-sms-verification-timer;
        margin-left: 10px;
    }

    input[type='text'] {
        @include font-size-16;
        display: block;
        width: 122px;
        height: 62px;
        border: 2px solid $color-a;
        border-radius: 8px;
        text-align: center;

        @include media($width-normal) {
            width: 60px;
        }

        &.one-code {
            @include font-size-18;
            width: 100%;
            letter-spacing: 30px;
            font-weight: 700;
            padding-left: 30px;
        }

        &.redBorder {
            border-color: $color-popup-red;
        }
    }

    .sms-verification-text-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

.app {
    &.signup-step-4-page,
    &.signin-page,
    &.auth-start-page,
    &.auth-forgot-send-page,
    &.auth-forgot-confirm-page {
        @include scroll-whole-page-without-color;
    }
}
