// LAYOUT
$width-big: 1024px;
$width-normal: 733px;
$width-small: 580px;
$width-800: 800px;
$width-vsmall: 483px;
$width-se: 375px;
$width-iphone12: 414px;
$width-popup: 984px;
$height-popup: 500px;
$width-popup-terms: 984px;
$height-popup-terms: 500px;

// COLOR
$white: #ffffff;
$black: #000000;
$grey: #919a9e;
$red: #ff5873;
$orange: #f90;
$blue-first: #e2f6ff;
$blue-second: #0075ac;
$color-body: #575757;
$color-grey-light: #f2f2f2;
$color-grey-dark: #575757;
$color-dark-blue: #0daaff;
$color-main: #000000;
$color-bg: #ffffff;
$color-a: #000000;
$color-disabled: #cccccc;
$color-error: $red;
$color-light-green: #ecf8fe;
$color-popup-bg-out: rgba(79, 79, 79, 0.64);
$color-popup-bg-in: $white;
$color-line-bg: #d3dadd;
$color-filezone-border: #cdcace;
$color-filezone-text: #575757;
$color-filezone-button-border: #575757;
$color-gradient-start: #31cbf8;
$color-gradient-end: #0075ac;
$color-black-08: rgba(0, 0, 0, 0.8);
$color-input-title: #a0a0a0;
$color-body-new: $color-black-08;
$color-inptut-blue: #1290c4;
//colors button
$color-button-next: #f90;
$color-button: #f90;
$color-button-hover: #f90;
$color-button-white: $white;
$color-button-text: $white;
$color-button-text-white: $black;
$color-button-link-blue: $color-dark-blue;
$color-hover-btn-white: #d3dadd;
$color-button-dashboard: #111;
//forms
$color-checkbox: #1d1d1b;
$color-desc: $grey;
$color-yesno-yes: #f90;
$color-yesno-no: #b6b6b6;
$color-yesno-notvalid: $red;
$color-select-hover: #00a5cd;
$color-select-selected: $color-dark-blue;
//theme
$color-header-bg: $color-dark-blue;
$color-header-secure-bg: $color-light-green;
$color-bottom-bg: rgba(255, 255, 255, 0.8);
$color-bottom-links-bg: #f2f2f2;
//popups
$color-popup-green: #77b62a;
$color-popup-red: $red;
//elements
$color-youtube-border: $grey;
$color-sms-verification-timer: $orange;
$color-contract-deleted: #f2f2f2;
$color-contract-provider-image-bg: #f2f2f2;
$color-contract-provider-image-border: #e0e0e0;
$color-contract-provider-image-text: #4f4f4f;
$item-border: #e0e0e0;
//statuses
$color-open: #919a9e;
$color-reject: $red;
$color-onwork: #f3ca3e;
$color-activate: #d6eef0;

// TYPOGRAPHY
$font-main: 'Gotham Narrow';

$font-size-10: 10px;
$line-height-10: 1.1;

$font-size-11: 11px;
$line-height-11: 1.27273;

$font-size-12: 12px;
$line-height-12: 14px;

$font-size-13: 13px;
$line-height-13: 1.38462;

$font-size-14: 14px;
$line-height-14: 1.28572;

$font-size-15: 15px;
$line-height-15: 18px;

$font-size-16: 16px;
$line-height-16: 20px;

$font-size-17: 17px;
$line-height-17: 24px;

$font-size-18: 18px;
$line-height-18: 3;

$font-size-20: 20px;
$line-height-20: 1.4;

$font-size-21: 21px;
$line-height-21: 28px;

$font-size-22: 22px;
$line-height-22: 30px;

$font-size-24: 24px;
$line-height-24: 1.33334;

$font-size-28: 24px;
$line-height-28: 38px;

$font-size-32: 32px;
$line-height-32: 44px;
