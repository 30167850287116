@import './variables';
@import './mixins';

.impressum-page {
    .top {
        @include column-center-center;
        background: $color-light-green;
        width: 100%;
    }
    .title {
        @include font-size-17;
        font-weight: 700;
        color: $color-black-08;
        line-height: 24px;
        padding: 10px 0px;
    }
    .text {
        @include font-size-17;
        font-weight: 300;
        color: $color-black-08;
        line-height: 24px;
    }
}
