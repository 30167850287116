//aligns
@mixin column {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
}
@mixin row {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}
@mixin column-center-center {
    @include column;
    justify-content: center;
    align-items: center;
}
@mixin column-top-stretch {
    @include column;
    justify-content: flex-start;
    align-items: stretch;
}
@mixin column-right-center {
    @include column;
    justify-content: center;
    align-items: flex-end;
}
@mixin column-right-top {
    @include column;
    justify-content: flex-start;
    align-items: flex-end;
}
@mixin column-left-top {
    @include column;
    justify-content: flex-start;
    align-items: flex-start;
}
@mixin column-left-spacebetween {
    @include column;
    justify-content: space-between;
    align-items: flex-start;
}
@mixin row-center-center {
    @include row;
    justify-content: center;
    align-items: center;
}
@mixin row-left-center {
    @include row;
    justify-content: flex-start;
    align-items: center;
}
@mixin row-left-end {
    @include row;
    justify-content: flex-start;
    align-items: flex-end;
}
@mixin row-right-center {
    @include row;
    justify-content: flex-end;
    align-items: center;
}
@mixin row-spacearound-center {
    @include row;
    justify-content: space-around;
    align-items: center;
}
@mixin row-spacebetween-center {
    @include row;
    justify-content: space-between;
    align-items: center;
}
@mixin row-spacebetween-start {
    @include row;
    justify-content: space-between;
    align-items: flex-start;
}
@mixin row-spacebetween-end {
    @include row;
    justify-content: space-between;
    align-items: flex-end;
}
@mixin row-left-top {
    @include row;
    justify-content: flex-start;
    align-items: flex-start;
}

// HELPERS
@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

// media
@mixin media($width) {
    @media screen and (max-width: $width) {
        @content;
    }
}

@mixin media-min($width) {
    @media screen and (min-width: $width) {
        @content;
    }
}

@mixin media-y($height) {
    @media screen and (max-height: $height) {
        @content;
    }
}

// TYPOGRAPHY
@mixin font-size-10 {
    font-size: $font-size-10;
    line-height: $line-height-10;
}

@mixin font-size-11 {
    font-size: $font-size-11;
    line-height: $line-height-11;
}

@mixin font-size-12 {
    font-size: $font-size-12;
    line-height: $line-height-12;

    @include media($width-800) {
        font-size: $font-size-10;
    }
}

@mixin font-size-13 {
    font-size: $font-size-13;
    line-height: $line-height-13;
}

@mixin font-size-14 {
    font-size: $font-size-14;
    line-height: $line-height-14;

    @include media($width-800) {
        font-size: $font-size-12;
    }

    //@include media-min($width-big) {
    //    font-size: $font-size-16;
    //}
}

@mixin font-size-15 {
    font-size: $font-size-15;
    line-height: $line-height-15;
}

@mixin font-size-16 {
    font-size: $font-size-16;
    line-height: $line-height-16;

    @include media($width-800) {
        font-size: $font-size-14;
    }

    //@include media-min($width-big) {
    //    font-size: $font-size-18;
    //}
}

@mixin font-size-17 {
    font-size: $font-size-17;
    line-height: $line-height-17;
}

@mixin font-size-18 {
    font-size: $font-size-18;
    line-height: $line-height-18;

    @include media($width-800) {
        font-size: $font-size-16;
    }

    @include media-min($width-big) {
        font-size: $font-size-20;
    }
}

@mixin font-size-20 {
    font-size: $font-size-20;
    line-height: $line-height-20;

    @include media($width-800) {
        font-size: $font-size-18;
    }

    @include media-min($width-big) {
        font-size: $font-size-22;
    }
}

@mixin font-size-21 {
    font-size: $font-size-21;
    line-height: $line-height-21;
}

@mixin font-size-22 {
    font-size: $font-size-22;
    line-height: $line-height-22;
}

@mixin font-size-24 {
    font-size: $font-size-24;
    line-height: $line-height-24;
}

@mixin font-size-28 {
    font-size: $font-size-28;
    line-height: $line-height-28;
}

@mixin font-size-32 {
    font-size: $font-size-32;
    line-height: $line-height-32;
}

@mixin font-style-h1 {
    font-size: $font-size-32;
    font-weight: 500;
    letter-spacing: 0.04em;
    padding: 30px 0px 40px;
    line-height: 44px;
}

@mixin font-style-h2 {
    font-size: $font-size-21;
    font-weight: 500;
    letter-spacing: 0.04em;
    padding: 30px 0px 20px;
    line-height: 28px;
}

@mixin font-style-h3 {
    font-size: $font-size-18;
    line-height: $line-height-18;
    font-weight: 500;
}

@mixin font-style-h4 {
    font-size: $font-size-14;
    line-height: 24px;
    font-weight: 500;
    margin: 24px 0px 16px;
}

@mixin font-style-h-other {
    font-size: $font-size-16;
    line-height: $line-height-16;
}

@mixin font-style-body {
    font-size: $font-size-17;
    line-height: $line-height-17;
    font-weight: 300;
}
@mixin font-style-caption {
    font-size: $font-size-12;
    line-height: $line-height-12;
}

// LAYOUT
@mixin flatlist {
    ol,
    ul {
        list-style: none;
        padding-left: 24px;
        text-indent: -24px;

        li {
            &:before {
                content: '\2022';
                display: inline-block;
                min-width: 24px;
                text-indent: 0;
                padding-right: 4px;
            }
        }
    }

    ol {
        li {
            counter-increment: list-ordered;

            &:before {
                content: counter(list-ordered) '. ';
                text-indent: 0;
            }
        }
    }
}

@mixin wrapper-xy($width: $width-normal, $padding: 16, $align: center) {
    @if $align == center {
        margin-left: auto;
        margin-right: auto;
    }

    @if $align == right {
        margin-left: auto;
    }

    width: $width;

    @include media($width) {
        width: auto;
        padding: 0 $padding + px;
    }
}

@mixin clearfix {
    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }
}

@mixin hidden {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    display: none;
}

@mixin visible {
    display: block !important;
    height: auto !important;
    opacity: 1 !important;
    overflow: visible !important;
    visibility: visible !important;
}

@mixin hyphenate {
    overflow-wrap: break-word;
    word-wrap: break-word;
    //hyphens: auto;
}

@mixin flex-flow($width, $margin) {
    display: flex;
    flex-wrap: wrap;
    margin: calc(#{$margin} * -1);

    > * {
        flex-grow: 1;
        margin: $margin;
        max-width: 100%;
        min-width: calc(#{$width} - (#{$margin} * 2));
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

@mixin touchArea {
    width: 44px;
    height: 44px;
    cursor: pointer;
}

// BUTTON
@mixin button($style: next) {
    border: 1px solid transparent;
    border-radius: 8px;
    display: inline-block;
    text-align: center;
    transition: all 0.2s ease-out;
    vertical-align: middle;
    white-space: nowrap;

    &:hover,
    &:focus {
        outline: 0;
        text-decoration: none;
    }

    @if $style == next {
        border-color: $color-button-next;
        background-color: $color-button-next;
        color: $white;

        &:visited {
            color: $white;
        }

        &:hover,
        &:focus {
            background-color: $color-button-next;
        }
    }
}

@mixin scroll-whole-page-without-color() {
    overflow-y: auto;
    height: auto;
    .header {
        position: static;
        top: auto;
    }
    .content {
        overflow-y: inherit;
    }
}

@mixin scroll-whole-page($bgColor: $white) {
    background-color: $bgColor;
    @include scroll-whole-page-without-color;
}

@mixin gradient-main() {
    background: linear-gradient(170.13deg, $color-gradient-start 0%, $color-gradient-end 100%);
    box-shadow: 0 0 50px 0 rgba(128, 128, 128, 0.3);
}
