@import './variables';
@import './mixins';

.onboarding-contract-add-page {
    .firms {
        @include row-center-center;
        margin-top: 32px;

        img {
            padding: 0px 16px;
        }
    }
}
.onboarding-products-page,
.onboarding-providers-page > {
    .content > {
        .wrapper {
            padding: 16px 0px 50px;
        }
        .wrapper-big {
            .selected-item {
                background-color: $color-grey-light;
                padding: 16px 0px;
                .wrapper {
                    @include row-left-center;
                    span {
                        padding: 5px;
                        @include font-size-18;
                        font-weight: 500;
                        color: $blue-second;
                    }
                }
            }
            @include media($width-big) {
                padding: 0px;
            }
        }
    }
}
.onboarding-providers-page > {
    .content {
        .custom-provider {
            position: fixed;
            bottom: 30px;
            width: 100%;
            background-color: $color-bottom-links-bg;

            .content {
                padding-bottom: 0px;
                .wrapper {
                    padding: 20px 0px;
                }
            }

            @include media($width-normal) {
                .title,
                input {
                    padding-left: 16px !important;
                }
            }
        }
    }

    .bottom {
        bottom: 30px;
    }
}
.onboarding-contract-edit-page {
    .line {
        width: 100%;
        height: 1px;
        background-color: $color-line-bg;
        margin: 5px 0px 0px;
    }
}

.onboarding-contract-list-page {
    .products {
        .item {
            @include row-spacebetween-center;
            margin-top: 16px;
            box-sizing: border-box;
            height: 70px;
            border-radius: 8px;
            border: 2px solid $blue-second;
            cursor: pointer;

            .item-inner {
                flex: 1;
                @include row-left-center;

                .img {
                    @include column-center-center;
                    width: 60px;
                }

                .title {
                    flex: 1;
                    margin-left: 5px;
                    @include font-size-16;
                    font-weight: 500;
                    color: $blue-second;
                }
            }

            .item-arrow {
                background-image: url('../assets/icon-arrow-right-orange.png');
                background-position: center left;
                background-repeat: no-repeat;
                width: 40px;
                height: 24px;
            }
        }
    }

    .contracts {
        padding: 4px 0px 30px;
        .item {
            @include row-left-top;
            margin-top: 16px;
            //padding: 16px 13px 5px 20px;
            box-sizing: border-box;
            border-radius: 8px;
            border: 2px solid $blue-second;
            flex-wrap: wrap;
            align-content: stretch;
            align-items: stretch;

            .provider-icon {
                padding: 16px 0px 5px 20px;
            }

            &.deleted {
                background-color: $color-contract-deleted;
            }

            .texts {
                padding: 16px 0px 5px 20px;
                flex: 1;
                margin-left: 16px;
                @include media($width-se) {
                    width: 220px;
                }

                .product {
                    @include font-size-17;
                    font-weight: 500;
                    color: $blue-second;
                }
                .provider {
                    @include font-size-17;
                    font-weight: 300;
                    color: $color-grey-dark;

                    .contract-number {
                        padding-left: 50px;

                        @include media($width-normal) {
                            padding-left: 5px;
                        }
                    }
                }

                .edit-link {
                    @include row-left-center;

                    .button-link {
                        @include font-size-15;
                    }
                }
            }

            .selecting {
                @include column-center-center;
                background: $orange;
                width: 140px;
                border-bottom-right-radius: 6px;
                border-top-right-radius: 6px;

                .text {
                    padding-top: 5px;
                    @include font-size-12;
                    text-align: center;
                    color: $white;
                }
                &.red {
                    .text {
                        color: $color-error;
                    }
                    .checkbox .checkbox-inner .element {
                        border-color: $color-error;
                    }
                }
            }

            .text-disable {
                color: $grey;
            }
            .text-blue {
                color: $white;
                @include font-size-17;
                text-align: center;
                font-weight: 500;
            }
        }
    }
}

.onboarding-contract-list-finish-page {
    .header-texts {
        margin-left: 65px;
    }
    .header-text {
        @include font-size-14;
        color: $white;
        background: url('../assets/icon-checkbox-white.png') left center no-repeat;
        margin-top: 10px;
        padding-left: 44px;
    }
    .content {
        margin-top: 32px;
        @include media($width-normal) {
            margin-top: 0px;
        }
    }
}

.onboarding-sign-broker-page,
.onboarding-sign-privacy-page {
    .link-text {
        @include font-size-14;

        span {
            cursor: pointer;
        }

        a {
            text-decoration: underline;
        }
    }
    .container-sign {
        width: 100%;
        height: 307px;
        border: 2px solid $black;
        border-radius: 8px;
        margin: 20px 0;
        position: relative;

        .inner {
            min-height: 250px;
            .signCanvas {
                width: 100%;
                //height: 100%;
                //aspect-ratio: auto auto;
            }
            .signCanvasMobile {
                //border: 1px solid blue;
                //height: 100%;
                //aspect-ratio: auto 350 / 260;
            }
        }

        .container-sign-bottom {
            @include row-spacebetween-center;
            padding: 0 5px 0 24px;

            .name {
                @include font-size-14;
                font-weight: 600;
            }

            .remove-sign {
                @include touchArea;
                background: url('../assets/icon-trash.png') center center no-repeat;
            }
        }

        .youtube-video {
            position: absolute;
            right: -180px;
            top: 5px;
            display: inline-block;
        }
    }

    .bottom-text {
        margin-top: 10px;
        padding-left: 40px;
        background: url('../assets/icon-check-blue.png') left center no-repeat;
    }

    .conditions {
        margin-top: 10px;

        .item {
            @include row-spacebetween-center;
            width: 100%;
            margin-top: 20px;
            .form-yesno {
                margin-top: 5px;
            }
        }
    }
    .maintext {
        margin-right: 20px;
    }
}
.onboarding-sign-broker-page {
    .link-text {
        padding-top: 32px;
    }
}
.onboarding-sign-privacy-page {
    .top-text {
        @include font-size-14;
        line-height: 20px;
    }
    .bottom-text {
        @include font-size-14;
        line-height: 20px;
        margin-top: 10px;
        padding-left: 0px;
        background: none;
    }
}
.onboarding-finapi-import-page {
    .finapi-webform {
        overflow: hidden;
        height: 100%;
        width: 100%;
    }
    .complete {
        @include media($width-normal) {
            @include font-size-16;
        }
    }
}
