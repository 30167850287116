@font-face {
    font-family: 'Gotham Narrow';
    src: url('../assets/fonts/GothamNarrow-Light.woff2') format('woff2'), url('../assets/fonts/GothamNarrow-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Narrow';
    src: url('../assets/fonts/GothamNarrow-Bold.woff2') format('woff2'), url('../assets/fonts/GothamNarrow-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Narrow Book';
    src: url('../assets/fonts/GothamNarrow-Book.woff2') format('woff2'), url('../assets/fonts/GothamNarrow-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Narrow';
    src: url('../assets/fonts/GothamNarrow-Medium.woff2') format('woff2'), url('../assets/fonts/GothamNarrow-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
